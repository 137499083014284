// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-td {
  position: relative;
  padding-right: 30px; /* Ensure space for the icon */
}

.pencil-icon {
  display: none; /* Hide the icon by default */
  position: absolute;
  right: 10px; /* Position the icon to the right */
  top: 20%;
  transform: translateY(-50%); /* Center the icon vertically */
  cursor: pointer; /* Change the cursor to a pointer */
}

.table-td:hover .pencil-icon {
  display: inline; /* Show the icon on hover */
}

.new-date-box {
  border-color: 1px solid #607980;
}

.ok-button-disabled:disabled,
.ok-button-disabled.disabled {
  background-color: gray !important;
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.date-picker-container {
  color: black;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/admin-profile/adminProfile.css"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA,EAAA,8BAAA;AACF;;AAEA;EACE,aAAA,EAAA,6BAAA;EACA,kBAAA;EACA,WAAA,EAAA,mCAAA;EACA,QAAA;EACA,2BAAA,EAAA,+BAAA;EACA,eAAA,EAAA,mCAAA;AACF;;AAEA;EACE,eAAA,EAAA,2BAAA;AACF;;AAEA;EACE,+BAAA;AACF;;AAEA;;EAEE,iCAAA;EACA,8BAAA;EACA,8BAAA;AACF;;AACA;EACE,YAAA;EACA,gBAAA;AAEF","sourcesContent":[".table-td {\n  position: relative;\n  padding-right: 30px; /* Ensure space for the icon */\n}\n\n.pencil-icon {\n  display: none; /* Hide the icon by default */\n  position: absolute;\n  right: 10px; /* Position the icon to the right */\n  top: 20%;\n  transform: translateY(-50%); /* Center the icon vertically */\n  cursor: pointer; /* Change the cursor to a pointer */\n}\n\n.table-td:hover .pencil-icon {\n  display: inline; /* Show the icon on hover */\n}\n\n.new-date-box {\n  border-color: 1px solid #607980;\n}\n\n.ok-button-disabled:disabled,\n.ok-button-disabled.disabled {\n  background-color: gray !important;\n  cursor: not-allowed !important;\n  pointer-events: all !important;\n}\n.date-picker-container{\n  color: black;\n  font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
