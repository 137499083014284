import React from 'react'
import DashboardHeader from '../dashboardheader/DashboardHeader'
import "./style.scss"
import { Outlet } from 'react-router-dom'

const DashBoard = () => {
  return (
      <div className='dashboard-bg'>
          <DashboardHeader />
          {/* <div className="unknown-div" /> */}
          <Outlet />
    </div>
  )
}

export default DashBoard