import { memo, useState } from 'react';
import * as React from 'react';
import { Input, Label } from 'reactstrap';
import { DropZoneProps } from './types';

export interface DropZoneRef {
  clearDropZone: (docType: string) => void;
}

const DropZone: React.ForwardRefRenderFunction<DropZoneRef, DropZoneProps> = (props: DropZoneProps, ref) => {

  React.useImperativeHandle(ref, () => ({
    clearDropZone
  }));

  const clearDropZone = (docType: string): void => {
    // @ts-ignore
    document.getElementById("fileInput" + docType).value = null;
  };

  const handleFileChange = (event: any) => {
    const file: any = event.target.files[0];
    if (file) {
      props.onFileUploaded(file, props.docType);
    }
  };

  return (
    <div>
      <Label htmlFor={"fileInput" + props.docType} className="btn btn-primary w-100 ">
        Upload Document
      </Label>
      <Input id={"fileInput" + props.docType} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpeg,.jpg" style={{ display: 'none' }} onChange={handleFileChange} />
    </div>
  );
};

export default memo(React.forwardRef(DropZone));
