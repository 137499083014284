// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unknown-div {
  min-height: 70px;
}

@media (max-width: 558px) {
  .unknown-div {
    min-height: 40px;
  }
}
.dashboard-bg {
  background-color: white;
  height: 100%;
  min-height: 100vh;
  color: black;
}

.width-full-page {
  width: 80%;
  z-index: 1;
}

@media (max-width: 768px) {
  .width-full-page {
    width: 100%;
    padding: 2%;
  }
}
.head-parent-div {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.head-text-dashboard {
  font-size: 18px;
  font-weight: 600;
  color: black;
  width: 20%;
  min-width: 200px;
}

.head-sub-text-dashboard {
  font-size: 14px;
  font-weight: 600;
  color: black;
  width: 20%;
  min-width: 200px;
}

.head-text-dashboard-value {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

.head-sub-text-dashboard-value {
  font-size: 14px;
  font-weight: 600;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/dashboard/style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEE;EACE;IACE,gBAAA;EACJ;AACF;AAEA;EACI,uBAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;AAAJ;;AAGA;EACI,UAAA;EACA,UAAA;AAAJ;;AAGA;EACI;IACE,WAAA;IACA,WAAA;EAAJ;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,mBAAA;AAAF;;AAGA;EACI,eAAA;EACA,gBAAA;EACA,YAAA;EACA,UAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,eAAA;EACA,gBAAA;EACA,YAAA;EACA,UAAA;EACA,gBAAA;AAAJ;;AAEA;EACE,eAAA;EACE,gBAAA;EACA,YAAA;AACJ;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,YAAA;AACF","sourcesContent":[".unknown-div {\n    min-height: 70px;\n  }\n\n  @media (max-width: 558px) {\n    .unknown-div {\n      min-height: 40px;\n    }\n  }\n\n.dashboard-bg{\n    background-color: white;\n    height: 100%;\n    min-height: 100vh;\n    color: black;\n}\n\n.width-full-page {\n    width: 80%;\n    z-index: 1;\n  }\n\n@media (max-width: 768px) {\n    .width-full-page {\n      width: 100%;\n      padding: 2%;\n    }}\n\n.head-parent-div{\n  display: flex;\n  flex-direction: row;\n  justify-content: left;\n  align-items: center;\n}\n\n.head-text-dashboard{\n    font-size: 18px;\n    font-weight: 600;\n    color: black;\n    width: 20%;\n    min-width: 200px;\n}\n\n.head-sub-text-dashboard{\n    font-size: 14px;\n    font-weight: 600;\n    color: black;\n    width: 20%;\n    min-width: 200px;\n}\n.head-text-dashboard-value{\n  font-size: 18px;\n    font-weight: 600;\n    color: black;\n}\n\n.head-sub-text-dashboard-value{\n  font-size: 14px;\n  font-weight: 600;\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
