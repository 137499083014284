import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { forwardRef, useImperativeHandle } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { updateEntity, updateEntityProperty } from './accounts.reducer';

interface PepProps {
  onFormComplete: (completed: boolean) => void;
}

export interface PepRef {
  syncPepWithServer: () => Promise<any>;
}

const Pep: React.ForwardRefRenderFunction<PepRef, PepProps> = ({ onFormComplete }: PepProps, ref) => {
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const dispatch = useAppDispatch();

  useImperativeHandle(ref, () => ({
    syncPepWithServer
  }));

  const syncPepWithServer = (): Promise<any> => {
    return dispatch(updateEntity({ ...mainAccountsEntity, last_level: 7 }));
  };

  const handleAreYouPepChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(updateEntityProperty({
      isPep: e.target.checked
    }));
  };

  const handleFamilyMemberPepChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(updateEntityProperty({
      isFamilyPep: e.target.checked
    }));
  };

  const handleFamilyMemberPepDetailsChange = e => {
    const { value } = e.target;
    dispatch(updateEntityProperty({ familymemberpepdetails: value }));
  };

  // Checking if both checkboxes are checked
  const isBothChecked = mainAccountsEntity?.isPep === true && mainAccountsEntity?.isFamilyPep === true;

  return (
    <div>
      <Row>
        <Col md="12" className="form-firstrow-account-head-custom">
          {`Politically Exposed Person (PEP)`}
        </Col>
        <Col md="12" className="pt-3">
          A “PEP” is defined as a person who is or has been entrusted with a prominent public function in Ireland or abroad, including:
          politicians, senior executives of government bodies, or immediate family members of the above.
        </Col>
        <Col md="12" className="pt-3">
          We are required by law to identify if any of our clients are PEPs and, if so, to apply certain checks and controls before opening
          an account for them or providing any services.
        </Col>
       
        <Col md="12 pt-1 d-flex">
          <FormGroup check>
            <Label check className="label-account-class">
              <Input
                type="checkbox"
                name="ARE_YOU_A_PEP"
                id="ARE_YOU_A_PEP"
                className="input-account-custom-bgnone"
                checked={mainAccountsEntity?.isPep === true}
                onChange={handleAreYouPepChange}
              />{' '}
              <Col md="12" className="label-account-class">
              I confirm that I am not and have never been entrusted with a prominent public function in Ireland or abroad, whether as a politician or as a senior executive of a government body
            </Col>
            </Label>
          </FormGroup>
        </Col>
       
        <Col md="12 d-flex">
          <FormGroup check>
            <Label check className="label-account-class">
              <Input
                type="checkbox"
                name="FAMILY_MEMBER_PEP"
                id="FAMILY_MEMBER_PEP"
                className="input-account-custom-bgnone"
                checked={mainAccountsEntity?.isFamilyPep === true}
                onChange={handleFamilyMemberPepChange}
              />{' '}
               <Col md="12" className="label-account-class">
                I confirm that no immediate family member have ever been entrusted with a prominent public function in Ireland or abroad, whether as a politician or as a senior executive of a government body
              </Col>
            </Label>
          </FormGroup>
        </Col>

        {/* Conditional rendering of the details section */}
        {!isBothChecked && (
          <Col md="12">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                Please Provide Details
                <br />
                <span className="account-sub-span-custom">
                  {`If you are unable to make each of the conformations required please provide details`}
                </span>
              </Label>
              <Input
                type="text"
                name="familymemberpepdetails"
                id="familymemberpepdetails"
                value={mainAccountsEntity?.familymemberpepdetails}
                className="textfield-style"
                onChange={handleFamilyMemberPepDetailsChange}
              />
            </FormGroup>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default forwardRef(Pep);
