import React, { memo, useEffect, useRef, useState } from 'react';
import { Col, Input, Label, Row, FormGroup, Form, Button } from 'reactstrap';
import './account.scss';
import { useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getAgreements, getSignature } from './accounts.reducer';
import { getPendingTask } from 'app/modules/profile/profile.reducer';
import { log } from 'react-jhipster';
import { getAdminAccounts } from '../admin-profile/admin.reduce';
import { useReactToPrint } from 'react-to-print';

const InteractiveBrokers = ({ interactive, accountHolderType, ibkrPrimary_sign, setibkrPrimarySign = (data)=>{}, ibkrSecondarySign,
  setibkrSecondarySign,
  handleCheckboxibustomerAgreement,
  handleCheckboxibclientFinancialAdvisor,
  handleCheckboxelt,
  handleCheckboxibsdNotification,
  handleCheckboxLLA,
  accountIdNum }) => {
  const location = useLocation();
  const signatureData = useAppSelector(state => state.accounts.signatureData);
  const dispatch = useAppDispatch();
  const { level, _signatureDataPending } = location.state || {};

  const [customerId, setCustomerId] = useState()
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);

  const { accountId } = useParams();

  const [applicationData, setApplicationData] = useState<any>({});
  const [newData, setNewData] = useState<any>({});

  useEffect(() => {
    if (accountId) {
      dispatch(getAdminAccounts({ accountId }))
        .then((response: any) => {
          if (response?.payload?.data) {
            setApplicationData(response?.payload?.data?.account_signature_response);
            setNewData(response?.payload?.data)

          }
        })
        .catch((error) => {
        });
    }
  }, [accountId]);  

  const handleLinkClick = async (e) => {
    e.preventDefault();
    try {
      await dispatch(getAgreements()).unwrap();
    } catch (error) {
      console.error('Error fetching agreements:', error);
    }
    open('/terms-and-disclosures')
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {

      const tokenParts = accessToken.split('.');

      if (tokenParts?.length === 3) {
        try {
          const payload = JSON.parse(atob(tokenParts[1]));

          if (payload?.sub) {
            const { sub } = payload;
            setCustomerId(sub)
          }
        } catch (error) {
          console.error("Failed to parse JWT payload", error);
        }
      }
    }
  }, []);

  const [secondaryId, setSecondaryId] = useState()
  useEffect(() => {
    dispatch(getPendingTask({}))
      .then((response: any) => {
        const signaturePending = response?.payload?.data?.signature_pending;

        if (signaturePending) {
          signaturePending.forEach((item) => {
            setSecondaryId(item.secondary_customer_id);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [dispatch]);

  useEffect(() => {
    if (accountIdNum) {
      dispatch(getSignature(accountIdNum));
    }
  }, [accountIdNum, dispatch]);
  useEffect(() => {
    if (signatureData?.ibkr_signature?.primary_sign != null) {
      setibkrPrimarySign(signatureData?.ibkr_signature?.primary_sign);

    }
  }, [signatureData]);



  const pageStyle = `
 
  @media print {

  @page {
  size: auto;
  margin-top: 20mm;
  margin-bottom: 20mm;
}
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
    body {
      font-size: 13px;
    }
 
    .no-print {
      display: none;
    }
 
    .new-form-bold-text,
    .new-form-italic-text,
    .new-form-underline-text {
      font-size: 13px;
      font-weight: bold;
      color: black;
    }
 
    .new-form-heading-text {
      font-weight: bold;
      font-size: 25px;
      color: black;
    }
 
    .new-form-light-text {
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
 
    .new-form-very-light-text {
      font-weight: 300;
      font-size: 12px;
      color: black;
    }
 
    .new-form-italic-text {
      font-style: italic;
    }
 
    .bordered-table {
      width: 100%;
      border-collapse: collapse;
    }
    .bordered-table th,
    .bordered-table td {
      border: 1px solid black;
      padding: 8px;
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
    .bordered-table th {
      background-color: #f2f2f2;
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
    .new-form-table-row-one {
      background: #607980;
      color: black;
      font-weight: 300;]
      font-size: 13px;
    }
    .new-form-table-row-two {
      background-color: #d5d8da;
      color: black;
      font-weight: 300;
      font-size: 13px;
    }
  }
 
`;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Statement of Suitability',
    onBeforePrint: () => console.log('before printing...'),
    onAfterPrint: () => console.log('after printing...'),
    pageStyle,
    removeAfterPrint: true,
  });
  const componentRef = useRef();

    const isPrintButtonEnabled = () => {
    if (accountHolderType === 'Individual' || applicationData?.account_type === 'Individual') {
      return !!ibkrPrimary_sign; 
    } else if (accountHolderType === 'Joint') {
      const isPrimaryEditable = customerId === mainAccountsEntity.customerId; 
      const isSecondaryEditable = customerId === secondaryId;

      const isPrimaryValid = isPrimaryEditable ? !!ibkrPrimary_sign : true; 
      const isSecondaryValid = isSecondaryEditable ? !!ibkrSecondarySign : true; 

      return isPrimaryValid && isSecondaryValid; 
    }
    return false; 
  };

  const [isButtonEnabled, setIsButtonEnabled] = useState(isPrintButtonEnabled());


  useEffect(() => {
    setIsButtonEnabled(isPrintButtonEnabled());
  }, [ibkrPrimary_sign, ibkrSecondarySign, accountHolderType, applicationData, customerId]);

  return (
    <div ref={componentRef}>
      <Row className='bg-white pt-2'>
        <Col md="12" >
          <div className=" justify-content-center">
            <Form className=" bg-white px-3 py-2">
              {/* <div className='logo-div-form'>
                <img className="img-fluid logo-form" src="../../../../../content/images/logo_starlight.png" />
              </div> */}
              <Row>
                <Col md="12" className="pt-3 text-start new-form-heading-text">
                Starlight Smart Account & Interactive Brokers Ireland <br /><br />
                </Col>
                <Col md="12 new-form-light-text">
                  Starlight Smart Reserve and Starlight Smart Invest are provided via the Starlight Smart Account, a self-directed advisory investment account opened in your name with our investment broker, <span className='' style={{ color: 'black', fontWeight: 'bold' }}>Interactive Brokers Ireland Limited.</span> <br /><br />
                </Col>
                <Col md="12 new-form-light-text">
                <span className='' style={{ color: 'black', fontWeight: 'bold' }}>Interactive Brokers</span> Ireland is a MIFID authorised investment firm regulated by the Central Bank of Ireland and is a wholly owned group subsidiary of <span className='' style={{ color: 'black', fontWeight: 'bold' }}>Interactive Brokers Ireland</span> Group LLC, an A- publicly rated and listed global investment firm with a market capitalisation exceeding US$32bn, US$14bn in equity capital and over 2.5 million customers. <br /><br />
                </Col>
                <Col md="12 new-form-light-text">
                  Your cash and investments in your account are 'client assets' and are fully segregated from the assets of <span className='' style={{ color: 'black', fontWeight: 'bold' }}>Interactive Brokers Ireland </span>in accordance with Central Bank of Ireland regulatory rules. In the unlikely event of <span className='' style={{ color: 'black', fontWeight: 'bold' }}>Interactive Brokers Ireland </span> failure or insolvency, your assets are not available or accessible to Interactive Brokers Ireland'S creditors and would be returned to you. <br /><br />
                </Col>
                <Col md="12 new-form-light-text">
                  Starlight Wealth does not accept, hold, or have any access to your cash or investments and so in the unlikely event of our failure, your cash and investments are not available or accessible to our creditors. <br /><br />
                </Col>
                
                <Col md="12 new-form-light-text"><br />
                  To open a Starlight Smart Reserve Account, you must agree to <span className='' style={{ color: 'black', fontWeight: 'bold' }}>Interactive Brokers Ireland'S</span> standard terms and disclosures.
                </Col>
                <Col md="12" className=" new-form-bold-text pt-3">
                  Interactive Brokers Ireland Standard Terms and Disclosures
                </Col> <br />
                <Col md="12">
                  <FormGroup check>
                    <Label className="label-account-class" check style={{fontSize:"14px"}}>
                      <Input
                        type="checkbox"
                        name="ib_customer_agreement"
                        checked={interactive?.ib_customer_agreement || newData?.ib_customer_agreement}
                        className="input-front-account-custom-bgnone"
                        onChange={handleCheckboxibustomerAgreement}
                      />{' '}
                      <a href="https://starwealth-dev.azureedge.net/development-storage/Interactive%20Brokers%20Ireland%20Ltd%20Customer%20AgreementForm4750.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#597177' }}>
                        Interactive Brokers Ireland Ltd Customer Agreement
                      </a>
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup check >
                    <Label className="label-account-class" check style={{fontSize:"14px"}}>
                      <Input
                        type="checkbox"
                        name="over18"
                        checked={interactive?.ib_client_financial_advisor || newData?.ib_client_financial_advisor}
                        className="input-front-account-custom-bgnone"
                        onChange={handleCheckboxibclientFinancialAdvisor}
                      />{' '}
                      <a href="https://starwealth-dev.azureedge.net/development-storage/Agreement%20Limiting%20Interactive%20Brokers%20LiabilityForm6108.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#597177' }}>
                      Interactive Brokers Ireland Ltd Client Financial Advisor Agreement
                      </a>
                    </Label>
                    {/* //test docType */}

                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup check>
                    <Label className="label-account-class" check style={{fontSize:"14px"}}>
                      <Input
                        type="checkbox"
                        name="over18"
                        checked={interactive?.elt || newData?.elt}
                        className="input-front-account-custom-bgnone"
                        onChange={handleCheckboxelt}
                      />{' '}
                      <a href="https://starwealth-dev.azureedge.net/development-storage/Form4772Advisory.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#597177' }}>
                        Essential Legal Terms for your Account
                      </a>
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup check>
                    <Label className="label-account-class" check style={{fontSize:"14px"}}>
                      <Input
                        type="checkbox"
                        name="over18"
                        checked={interactive?.lla || newData?.lla}
                        className="input-front-account-custom-bgnone"
                        onChange={handleCheckboxLLA}
                      />{' '}
                      <a href="https://starwealth-dev.azureedge.net/development-storage/Legal%20AcknowledgementForm2109.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#597177' }}>
                        Limited Liability Agreement
                      </a>
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup check>
                    <Label className="label-account-class" check style={{fontSize:"14px"}}>
                      <Input
                        type="checkbox"
                        name="over18"
                        checked={interactive?.ibsd_notification || newData?.ibsd_notification}
                        className="input-front-account-custom-bgnone"
                        onChange={handleCheckboxibsdNotification}
                      />{' '}
                      <a href="https://starwealth-dev.azureedge.net/development-storage/Interactive%20Brokers%20Ireland%20Ltd%20Retail%20Client%20NotificationForm4769.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#597177' }}
                        onClick={handleLinkClick}
                      >
                        Interactive Broker standard disclosures and notifications (click to view all)
                      </a>
                    </Label>
                  </FormGroup>
                </Col>
                <div>
                  <br />
                  <div>
                    <label className='signature-container-inside-box-2'>Please tick the boxes above and type your FullName in the space below to confirm that standard terms and disclosures.</label>
                  </div>
                  <div className="col-md-6 signature-container-inside-box-2"style={{fontSize:"14px"}}>
                    <div>
                      {(accountHolderType === 'Individual' || applicationData?.account_type === 'Individual') ? (
                        <>

                          <div>
                            <Input
                              type="text"
                              className="signature-container-inside-box-2-input"
                              value={ibkrPrimary_sign || applicationData?.ibkr_signature?.primary_sign}
                              onChange={e => setibkrPrimarySign(e.target.value)}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          {/* <div>
                            <label>Please tick the boxes above and type your fullName in the space below to confirm that standard terms and disclosures.</label>
                          </div> */}
                          <div>
                            {customerId === mainAccountsEntity?.customerId ? (
                              <Input
                                type="text"
                                className="signature-container-inside-box-2-input"
                                value={_signatureDataPending?.account_type === 'Joint' ? _signatureDataPending?.ibkr_signature
                                  ?.primary_sign : ibkrPrimary_sign || ''}
                                onChange={e => setibkrPrimarySign(e.target.value)}
                                id="signPrimary"

                              />
                            ) : (
                              <Input
                                type="text"
                                className="signature-container-inside-box-2-input"
                                value={_signatureDataPending?.account_type === 'Joint' ? _signatureDataPending?.ibkr_signature
                                  ?.primary_sign : ibkrPrimary_sign || applicationData?.ibkr_signature?.primary_sign}
                                id="signPrimary"
                                readOnly

                              />
                            )}
                          </div>
                          <div>
                            <label>Signature of Secondary owner</label>
                          </div>
                          <div>
                            {customerId !== (secondaryId || mainAccountsEntity?.secondary_customer_id) ? (
                              <Input
                                type="text"
                                id="signSecondary"
                                className="signature-container-inside-box-2-input"
                                value={ibkrSecondarySign || applicationData?.ibkr_signature?.joint_sign}
                                readOnly

                              />
                            ) : (
                              <Input
                                type="text"
                                id="signSecondary"
                                className="signature-container-inside-box-2-input"
                                value={ibkrSecondarySign || ''}

                                onChange={e => setibkrSecondarySign(e.target.value)}

                              />
                            )}
                          </div>

                        </>
                      )
                      }
                    </div>
                  </div>
                </div>
              </Row>
              <Row>
                <FormGroup>
                  <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                </FormGroup>
              </Row>
              <Col md="12" className="text-end no-print">
                <Button className="print_btn_w8" disabled={!isButtonEnabled}  onClick={() => handlePrint({ content: () => document.body })}>Print</Button>
              </Col>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );

}
export default memo(InteractiveBrokers);
