import React, { useState } from 'react'
import { HouseDoor } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import "./style.scss"
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Popover,
    PopoverHeader,
    PopoverBody,
} from "reactstrap";
  
const navbarlinks = [
    {
      label: "My Starlight",
      value: "Home",
      navlink: "/dashboard",
    },
    {
      label: "Deposit",
      value: "Deposit",
      navlink: "/dashboard/",
    },
    {
      label: "Withdraw",
      value: "Withdraw",
      navlink: "/dashboard/",
    },
  
  ];
const DashboardHeader = () => {
    const [isOpen, setIsOpen] = useState(false);
    const Toggle = () => setIsOpen(!isOpen);
    const [selecetedNav, setSelecetdNav] = useState("Home");

    const handleSetActiveNavigation = (nav: string) => {
        setSelecetdNav(nav);
;
      };

  return (
      <header className="header-style header-style-custom ">
          <Navbar
          color="light"
          light
          expand="lg"
          className="navhead navbar-container pt-2"
        >
          <NavbarToggler onClick={Toggle} />

          <NavbarBrand tag={Link} to="/https://www.starlightwealth.ie" className='color-nav-brand'>
      {/* <span className="brand-title d-flex color-nav-brand">
        STARLIGHT WEALTH
      </span> */}
                  <img className="img-fluid logo-dashboard" src="../../../../../content/images/black_new_logo.svg" />
          </NavbarBrand>

          <Collapse
            isOpen={isOpen}
            navbar
            className="collapse-custom text-start"
          >
            <Nav className=" flex-column  flex-lg-row">
              {navbarlinks.map((item) => (
                <NavItem onClick={() => handleSetActiveNavigation(item.value)}>
                  <NavLink
                    tag={Link}
                    className={`nav-link nav-text relative-div-bg ${
                      selecetedNav === item.value ? "navbaractive" : ""
                    }`}
                    to={item.navlink}
                  >
                    <div
                      className={`bottom-border-active ${
                        selecetedNav === item.value ? "" : "d-none"
                      }`}
                    />
                    {item.label}
                  </NavLink>
                </NavItem>
              ))}
                      
                      <NavItem >
                  <NavLink
                    tag={Link}
                    className={`nav-link nav-text relative-div-bg ${
                      selecetedNav === "logout" ? "navbaractive" : ""
                    }`}
                    to={"/"}
                  >
                    <div
                      className={`bottom-border-active ${
                        selecetedNav === "logout"  ? "" : "d-none"
                      }`}
                    />
                    Log Out
                  </NavLink>
                </NavItem>
                  </Nav>
              </Collapse>
          </Navbar>
      </header>

  )
}

export default DashboardHeader