import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Col, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { updateEntity, updateEntityProperty } from './accounts.reducer';

interface AntiMoneyProps {
  onFormComplete: (completed: boolean) => void;
}

export interface AntiMoneyRef {
  syncAntiMoneyWithServer: () => Promise<any>;
}

const AntiMoney: React.ForwardRefRenderFunction<AntiMoneyRef, AntiMoneyProps> = ({ onFormComplete }: AntiMoneyProps, ref) => {
  const MANDATORY: string = 'This field is mandatory';
  const VALUE_EXCEEDED: string = 'Value cannot exceed 100%';
  const DIGITS_ONLY: string = 'Digits only';
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const dispatch = useAppDispatch();
  const [investmentFromEmploymentIncome, setInvestmentFromEmploymentIncome] = useState<string>(mainAccountsEntity.investmentFromEmploymentIncome || '');
  const [investmentFromPropertyGains, setInvestmentFromPropertyGains] = useState<string>(mainAccountsEntity.investmentFromPropertyGains || '');
  const [investmentFromGifts, setInvestmentFromGifts] = useState<string>(mainAccountsEntity.investmentFromGifts || '');
  const [investmentFromInvestmentGains, setInvestmentFromInvestmentGains] = useState<string>(mainAccountsEntity.investmentFromInvestmentGains || '');
  const [investmentFromOthers, setInvestmentFromOthers] = useState<string>(mainAccountsEntity.investmentFromOthers || '');
  const [inverstmentFromOthersInfo, setInvestmentFromOthersInfo] = useState<string>(mainAccountsEntity.inverstmentFromOthersInfo || '');
  const [showInvestmentInfo, setShowInvestmentInfo] = useState(!!mainAccountsEntity.inverstmentFromOthersInfo);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [totalValueError, setTotalValueError] = useState('');

  useImperativeHandle(ref, () => ({
    syncAntiMoneyWithServer
  }));

  const syncAntiMoneyWithServer = (): Promise<any> => {
    return dispatch(updateEntity({ ...mainAccountsEntity, last_level: 5 }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    const lcl_errors: { [key: string]: string } = {};
    const validValue = /^\d*$/.test(value) && Number(value) >= 0 && Number(value) <= 100;

    if (name === 'investmentFromEmploymentIncome') {
      setInvestmentFromEmploymentIncome(value);
      handleFieldChange(value, 'investmentFromEmploymentIncome', lcl_errors, validValue);
    } else if (name === 'investmentFromPropertyGains') {
      setInvestmentFromPropertyGains(value);
      handleFieldChange(value, 'investmentFromPropertyGains', lcl_errors, validValue);
    } else if (name === 'investmentFromGifts') {
      setInvestmentFromGifts(value);
      handleFieldChange(value, 'investmentFromGifts', lcl_errors, validValue);
    } else if (name === 'investmentFromInvestmentGains') {
      setInvestmentFromInvestmentGains(value);
      handleFieldChange(value, 'investmentFromInvestmentGains', lcl_errors, validValue);
    } else if (name === 'investmentFromOthers') {
      setInvestmentFromOthers(value);
      handleFieldChange(value, 'investmentFromOthers', lcl_errors, validValue, true);
      if (!value) {
        setInvestmentFromOthersInfo('');
        setShowInvestmentInfo(false);
      } else {
        setShowInvestmentInfo(true);
      }
    } else if (name === 'inverstmentFromOthersInfo') {
      setInvestmentFromOthersInfo(value);
      if (investmentFromOthers && !value) {
        lcl_errors.inverstmentFromOthersInfo = MANDATORY;
      } else {
        lcl_errors.inverstmentFromOthersInfo = '';
      }
      dispatch(updateEntityProperty({ inverstmentFromOthersInfo: value }));
      setShowInvestmentInfo(!!value);
    }

    setErrors(lcl_errors);
    validateTotalPercentage();
  };

  const handleFieldChange = (value: string, fieldName: string, field_errors: { [key: string]: string }, validValue: boolean, isOthers: boolean = false) => {
    let newValue = '0';
    if (validValue) {
      errors[fieldName] = '';
      newValue = value;
    } else if (value === '') {
      errors[fieldName] = '';
      newValue = '0';
    } else if (Number(value) > 100) {
      errors[fieldName] = VALUE_EXCEEDED;
    } else {
      errors[fieldName] = DIGITS_ONLY;
    }

    if (isOthers) {
      setShowInvestmentInfo(!!value);
    }

    dispatch(updateEntityProperty({ [fieldName]: newValue }));
  };

  const validateTotalPercentage = () => {
    const totalPercentage =
      Number(investmentFromEmploymentIncome || '0') +
      Number(investmentFromPropertyGains || '0') +
      Number(investmentFromGifts || '0') +
      Number(investmentFromInvestmentGains || '0') +
      (investmentFromOthers ? Number(investmentFromOthers) : 0);

    if (totalPercentage !== 100) {
      setTotalValueError('Total of the values must meet 100%');
    } else {
      setTotalValueError('');
    }
    const formComplete =
      totalPercentage === 100 &&
      (!investmentFromOthers || (investmentFromOthers && inverstmentFromOthersInfo && inverstmentFromOthersInfo !== ''));

    onFormComplete(formComplete);
  };

  useEffect(() => {
    validateTotalPercentage();
  }, [
    investmentFromEmploymentIncome,
    investmentFromPropertyGains,
    investmentFromGifts,
    investmentFromInvestmentGains,
    investmentFromOthers,
    inverstmentFromOthersInfo,
  ]);

  return (
    <>
      <div>
        <Row>
          <Col md="12" className="form-firstrow-account-head-custom pt-3">
            Anti-Money Laundering Requirements
          </Col>
          <Col md="12" className="pt-3">
            The following sections are required for us to meet our obligations under Anti-Money Laundering and other Regulations
          </Col>
          {/* <Col md="12" className="pt-3">
            For joint applications, please answer for both parties
          </Col> */}
        </Row>
        <Row>
          <FormGroup>
            <Col md="12" className="under-border-div mt-1 mb-1"></Col>
          </FormGroup>
        </Row>
      </div>
      <Row>
        <Col md="12" className="form-firstrow-account-head-custom">
          Source Of Wealth
        </Col>
        <Col md="12" className="pt-2">
          {`To meet Anti-Money Laundering Regulations, we need to know the source of the funds to be invested with us (the "Investment
          Amount").`}
        </Col>
        <Col md="12" className="pt-2">
          Please indicate the Source of Wealth by putting percentages beside each relevant source
        </Col>
        <Col className="pt-3">The total percentage should add up to 100%</Col>
        <Col md="12 pt-3">
          <FormGroup>
            <Label className="label-account-class">
              Employment Income %<br />
              <span className="account-sub-span-custom">What % of your Investment Amount is from your Employment Income?</span>
            </Label>
            <Input
              type="tel"
              name="investmentFromEmploymentIncome"
              id="investmentFromEmploymentIncome"
              value={investmentFromEmploymentIncome}
              defaultValue={mainAccountsEntity?.investmentFromEmploymentIncome}
              className="textfield-style"
              onChange={handleInputChange}
            />
            {errors.investmentFromEmploymentIncome && (
              <div className="error-message" style={{ color: 'red' }}>
                {errors.investmentFromEmploymentIncome}
              </div>
            )}
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <Label className="label-account-class">
              Property %<br />
              <span className="account-sub-span-custom">What % of your Investment Amount is from property gains?</span>
            </Label>
            <Input
              type="tel"
              name="investmentFromPropertyGains"
              id="investmentFromPropertyGains"
              value={investmentFromPropertyGains}
              defaultValue={mainAccountsEntity.investmentFromPropertyGains}
              className="textfield-style"
              onChange={handleInputChange}
            />
            {errors.investmentFromPropertyGains && (
              <div className="error-message" style={{ color: 'red' }}>
                {errors.investmentFromPropertyGains}
              </div>
            )}
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <Label className="label-account-class">
              Gifts %<br />
              <span className="account-sub-span-custom">What % of your Investment Amount is from gifts?</span>
            </Label>
            <Input
              type="tel"
              name="investmentFromGifts"
              id="investmentFromGifts"
              value={investmentFromGifts}
              defaultValue={mainAccountsEntity.investmentFromGifts}
              className="textfield-style"
              onChange={handleInputChange}
            />
            {errors.investmentFromGifts && (
              <div className="error-message" style={{ color: 'red' }}>
                {errors.investmentFromGifts}
              </div>
            )}
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <Label className="label-account-class">
              Investment Gains %<br />
              <span className="account-sub-span-custom">What % of your Investment Amount is from investment gains?</span>
            </Label>
            <Input
              type="tel"
              name="investmentFromInvestmentGains"
              id="investmentFromInvestmentGains"
              value={investmentFromInvestmentGains}
              defaultValue={mainAccountsEntity.investmentFromInvestmentGains}
              className="textfield-style"
              onChange={handleInputChange}
            />
            {errors.investmentFromInvestmentGains && (
              <div className="error-message" style={{ color: 'red' }}>
                {errors.investmentFromInvestmentGains}
              </div>
            )}
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <Label className="label-account-class">
              Others %<br />
              <span className="account-sub-span-custom">What % of your Investment Amount is from other sources?</span>
            </Label>
            <Input
              type="tel"
              name="investmentFromOthers"
              id="investmentFromOthers"
              value={investmentFromOthers}
              defaultValue={mainAccountsEntity.investmentFromOthers}
              className="textfield-style"
              onChange={handleInputChange}
            />
            {errors.investmentFromOthers && (
              <div className="error-message" style={{ color: 'red' }}>
                {errors.investmentFromOthers}
              </div>
            )}
          </FormGroup>
        </Col>
        {showInvestmentInfo && (
          <Col md="12">
            <FormGroup>
              <Label className="label-account-class">
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
              Other description <br />
                <span className="account-sub-span-custom">
                  {`Please provide additional information for the "Others" percentage`}
                </span>
              </Label>
              <Input
                type="text"
                name="inverstmentFromOthersInfo"
                id="inverstmentFromOthersInfo"
                value={inverstmentFromOthersInfo}
                defaultValue={mainAccountsEntity.inverstmentFromOthersInfo}
                className="textfield-style"
                onChange={handleInputChange}
              />
              {errors.inverstmentFromOthersInfo && (
                <div className="error-message" style={{ color: 'red' }}>
                  {errors.inverstmentFromOthersInfo}
                </div>
              )}
            </FormGroup>
          </Col>
        )}
        <Col md="12">
          {totalValueError && (
            <div className="error-message" style={{ color: 'red' }}>
              {totalValueError}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default forwardRef(AntiMoney);
