import { useState, useEffect } from 'react';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Progress, Row } from 'reactstrap';
import { isEmpty } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, getCountryList, updateEntity } from './company-accounts-reducer';
import CompanyDetails from './company-details';
import SecurityQuestion from './security-questions';
import CompanyInitialRequirement from './initial-page';


import './company.scss';
import CompanyPeople from './CompanyPeople';
import AuthorizedPeopleForm from './AuthorizedPeopleForm';
import CompanySos from './company-sos';
import { getBankList, getEntity, getState, signature, updateEntityProperty } from '../accounts/accounts.reducer';

import 'react-toastify/dist/ReactToastify.css';
import CommonToast from 'app/modules/components/CommonToast';
import { saveInitialPath } from 'app/modules/account/register/register.reducer';
import Summary from './summary';
import CompanyFinancials from './company-financials';
import { MpsdDoc } from './mpsd-doc';
import CompanyIbkr from './company-ibkr';

export const CompanyAccounts = () => {
  const dispatch = useAppDispatch();
  //-------------------------------------------------------------------------------------
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const { last_level } = useAppSelector(state => state.accounts.entity);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [showdiv, setShowdiv] = useState(last_level);
  const [countryList, setCountryList] = useState<string[]>([]);
  const [bankList, setBankList] = useState<string[]>([]);
  const [open7, setOpen7] = useState(false);
  const [stateList, setStateList] = useState<string[]>([]);
  const [ibkrCompany_sign, setibkrCompany_Sign] = useState<string | null>(null);
  const topSectionHolder = document.querySelector('#topSectionHolder');


  const scrollToTop = () => {
    setTimeout(() => {
      if (topSectionHolder) {
        topSectionHolder.scrollIntoView({ behavior: 'smooth' });
      }
    }, 50);
  }

  const [interactive, setInteractive] = useState({

    ib_customer_agreement: false,
    ib_client_financial_advisor: false,
    elt: false,
    ibsd_notification: false,
    lla: false,
  })

  const [key_person_details, setkey_person_details] = useState({
    authorized_person_details: [
      {
        name: '',
        role: '',
        firstname: '',
        lastname: '',
        passport_number: '',
        company_email_address: '',
        mobile_number: '+353',
        home_address: '',
        account_number: '',
        customer_id: '',
        date_of_bith: '',
        issued_country: 'Ireland',
        passport_expiry_date: '',
        country_of_birth_and_citizen: 'Ireland',
        pps_number: '',
        country_of_tax_residency: 'Ireland',
        owns_more_25_of_company: false,
        source_of_person_wealth: '',
        passport_doc: '',
        address: {
          country: 'Ireland',
          state: 'Galway',
          city: '',
          postal_code: '',
          street: '',
        },
        proof_of_address: '',

      }
    ]
  });
  const [companyAddressDetails, setCompanyAddressDetails] = useState({

    accountHolderMobileNumber: '+353',
    state_or_province: 'Galway',
    townorcity: '',
    country: 'Ireland',
    postcode: '',
    addressLine1: '',

  });
  const toggle2 = () => {
    setOpen2(value3 => !value3);
  };
  const toggle3 = () => {
    setOpen3(value3 => !value3);
  };
  const toggle7 = () => {
    setOpen7(value7 => !value7);
  };
  let accessToken = localStorage.getItem('jhi-authenticationToken');
  const navigate = useNavigate();
  const companyData = useAppSelector(state => state.accounts.entity);
  const companyDetail = useAppSelector(state => state?.company?.entity);
  const signatureData = useAppSelector(state => state.accounts.signatureData);
  const [numberOfForms, setNumberOfForms] = useState(1);

  const [AccountIdNum, setAccountId] = useState('');
  const [benificialOwner, setbenificialOwner] = useState('');

  const [open, setOpen] = useState(false);

  const [selectedOptionLIE, setSelectedOptionLIE] = useState('');
  const [emailError, setEmailError] = useState('');
  const [validatePhoneError, setValidationPhoneError] = useState('');
  const [proof_of_company_doc, setproof_of_company_doc] = useState<string | null>(null);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [onBehalfOf, setOnBehalfOf] = useState('');
  const [authorisePosition, setAuthorisePosition] = useState('');
  const [smartReserveAuthorisePosition, setSmart_reserve_authorise_position] = useState('');
  const [authorisedPosition3, setAuthorisedPosition3] = useState('')
  const [nameValues, setNameValues] = useState('');
  const [investError, setInvestError] = useState('');
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [compnyList, setCompanyList] = useState([])
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const [accountsIdNumber, setAccountsIdNumber] = useState('')
  const [liquidnetWorth, setLiqudnetWorth] = useState('')
  const [smart_reserve_primary_sign, setsmart_reserve_primary_sign] = useState<string | null>(null);
  const [sosPrimary_sign, setsosPrimarySign] = useState<string | null>(null);
  const [customerId, setCustomerId] = useState()
  const accountsEntity = useAppSelector(state => state.accounts.entity.accountId);
  const [showdivprogress, setShowdivprogress] = useState(0);
  const [showdivper, setShowdivper] = useState(0);

  useEffect(() => {
    accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {

      if (window.location.pathname === '/accounts') {
        navigate('/accounts')
      } else {
        if (window.location.pathname === '/company') {
          navigate('/company')
        } else if (window.location.pathname === '/user-profile') {
          navigate('/user-profile')
        } else if (window.location.pathname === '/admin-profile') {
          navigate('/admin-profile')
        }
      }
    } else {
      dispatch(saveInitialPath(window.location.pathname))
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {
      const tokenParts = accessToken?.split('.');
      if (tokenParts?.length === 3) {
        try {
          const payload = JSON.parse(atob(tokenParts[1]));
          if (payload?.sub) {
            const { sub } = payload;
            setCustomerId(sub)
          }
        } catch (error) { /* empty */ }
      }
    }
  }, []);
  //newcode

  const handleCheckboxLLA = e => {
    setInteractive({ ...interactive, lla: e.target.checked });
  };
  const handleCheckboxibustomerAgreement = e => {
    setInteractive({ ...interactive, ib_customer_agreement: e.target.checked });
  };

  const handleCheckboxibclientFinancialAdvisor = e => {
    setInteractive({ ...interactive, ib_client_financial_advisor: e.target.checked });
  };
  const handleCheckboxelt = e => {
    setInteractive({ ...interactive, elt: e.target.checked });
  };
  const handleCheckboxibsdNotification = e => {
    setInteractive({ ...interactive, ibsd_notification: e.target.checked });
  };

  const companyDetailsRef = React.useRef<{ syncCompanyDetailsWithServer: () => Promise<any> }>(null);
  const [companyDetailsButton, setCompanyDetailsButton] = useState<boolean>(false);

  const handleCompanyDetailsButtonSubmit = async (): Promise<void> => {
    if (companyDetailsRef.current) {
      try {
        const response = await companyDetailsRef.current.syncCompanyDetailsWithServer();



        if (response.payload.data.id) {

          setShowdiv((prev: number) => prev + 1);
          scrollToTop();

        } else {
          // Handle other response statuses if necessary
        }
      } catch (error) {
        // Handle error if necessary
        console.error('Error:', error);
      }
    }
  };


  const clearsecondValidationError = (field: string) => {

    setValidationErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });

  };
  const handleOnBehalfOf = (e) => {
    setOnBehalfOf(e.target.value);
  };

  const handleAuthorisePosition = (e) => {
    setAuthorisePosition(e.target.value);
  };

  const handleAuthoriseMPSDPosition = (e) => {
    setSmart_reserve_authorise_position(e.target.value);
  };
  const handleAuthoriseIbkrPosition = (e) => {
    setAuthorisedPosition3(e.target.value);
  };
  const AuthorizedPersonRef = React.useRef<{ syncAuthorizedPersonRefServer: () => Promise<any> }>(null);
  const [AuthorizedPersonButton, setAuthorizedPersonButton] = useState<boolean>(false);
  // new
  const companyFinancialRef = React.useRef<{ syncCompanyFinancialWithServer: () => Promise<any> }>(null);
  const [companyFinancialButton, setCompanyFinancialButton] = useState<boolean>(false);

  const handleCompanyFinancialSubmit = async (): Promise<void> => {
    if (companyFinancialRef.current) {
      const response = await companyFinancialRef.current.syncCompanyFinancialWithServer();

      if (response) {
        setShowdiv((prev: number) => prev + 1);
        scrollToTop();
      }
    }
  };
  const SummaryRef = React.useRef<{ syncSummaryWithServer: () => Promise<any> }>(null);

  const [SummaryButton, setSummaryButton] = useState<boolean>(false);



  const handleSummarySubmit = async (): Promise<void> => {

    if (SummaryRef.current) {

      const response = await SummaryRef.current.syncSummaryWithServer();



      if (response) {

        setShowdiv(6);

        scrollToTop();

      }


    }

  };

  useEffect(() => {
    if (accessToken) {
      if (showdiv === 2 || showdiv === 4 || showdiv === 7) {
        dispatch(getState()).then((res) => {

          if (res.payload) {
            const stateData = res.payload.map((item: any) => item);
            setStateList(stateData);
          }
        });
      }
    }
  }, [showdiv, dispatch]);
  useEffect(() => {
    if (accessToken) {

      if (showdiv === 4 || showdiv === 7 || showdiv === 2) {
        dispatch(getCountryList()).then((res) => {

          if (res.payload) {
            const countryData = res.payload.map((item: any) => item);
            setCountryList(countryData);
          }

        });
      }
    }
  }, [showdiv, dispatch]);


  useEffect(() => {
    if (accessToken) {

      if (showdiv === 5 || showdiv === 7) {
        dispatch(getBankList()).then((res) => {

          if (res.payload) {
            const bankData = res.payload.map((item: any) => item);
            setBankList(bankData);
          }

        });
      }
    }
  }, [showdiv, dispatch]);

  const customerDetails = useAppSelector(state => state.customer.entities);

  const handleContinue = () => {
    setOpen7(false);

    setOpen2(value2 => !value2);

    if (open7 === false) {
      setOpen2(true);
    }
  };

  useEffect(() => {

    if (customerDetails || mainAccountsEntity) {
      setName(customerDetails?.firstName || customerDetails?.accountHolderName || '');
      setLastName(customerDetails?.lastName || customerDetails?.accountHolderSurname || '');

      setCompanyAddressDetails(prevState => ({
        ...prevState,
        state_or_province: companyAddressDetails?.state_or_province || 'IE-MN',
      }));

      const updatedProperties = {
        state_or_province: mainAccountsEntity?.state_or_province || 'IE-MN',
        // country: mainAccountsEntity.country || 'Ireland',
        issued_country: mainAccountsEntity?.issued_country || 'Ireland',
        company_details: {
          ...mainAccountsEntity.company_details,
          authorized_person_details: mainAccountsEntity?.company_details?.authorized_person_details?.map(person => ({
            ...person,
            country_of_tax_residency: person?.country_of_tax_residency || 'Ireland', // Set default tax residency country to 'Ireland' if null
            address: {
              ...person.address,
              country: person?.address?.country || 'Ireland',
            }
          }))
        }

      };


      setkey_person_details({
        authorized_person_details: updatedProperties.company_details.authorized_person_details
      });

      // dispatch(updateEntityProperty(updatedProperties));
    }
  }, [customerDetails]);


  const [validationErrror, setValidationError] = useState('');
  const [rawValue, setRawValue] = useState(mainAccountsEntity?.totalNetWorth || '');

  const [isEditingCompany, setIsEditingCompany] = useState(false);

  const handleCompanyEditClick = () => {
    setIsEditingCompany(true);
  };

  const handleCompanyUpdateClick = () => {
    const updateBody = {
      investmentAmount: initialRequirements.investmentAmount,
      productType: [initialRequirements.USDReserve ? 'USD Reserve' : null, initialRequirements.EURReserve ? 'EUR Reserve' : null].filter(
        value => value !== null,
      ),
      is_company_account: true,
      accountId: AccountIdNum,
      isTaxResidentInIreland: companyDetails?.tax_residency,
      totalNetWorth: Number(FormDataNetworth.totalNetWorth),
      liquidNetWorth: Number(FormDataNetworth.liquidnetworth),
      totalNetIncome: Number(FormDataNetworth.totalnetincome),

      accountHolderName: FormDataNetworth.accountHolderName,
      nameOfBank: FormDataNetworth.bankName,
      iban: FormDataNetworth.iban,

      securityQuestionAnswer:
        initialRequirementsQuestions.securityquestionmotherandfathermeet ||
        initialRequirementsQuestions.securityquestioncartooncharacter ||
        initialRequirementsQuestions.securityquestionfirstpet ||
        '',
      securityQuestionAnswer1:
        FormDataQuestions2.securityquestionsportsteam ||
        FormDataQuestions2.securityquestiongrandmothername ||
        FormDataQuestions2.securityquestionbestfriend ||
        '',
      securityQuestionAnswer2:
        FormDataQuestions3.securityquestionnameofschool ||
        FormDataQuestions3.securityquestionfatheroccupation ||
        FormDataQuestions3.securityquestionfavouritebook ||
        '',

      company_details: {
        company_name: companyDetails.companyname,
        trading_name: companyDetails.tradingName,
        registration_number: companyDetails.companyRegistrationNumber,

        lei: companyDetails.Lei,
        legal_entity_identifier: companyDetails.selectedOptionLIE ? true : false,
        tax_registration_number: companyDetails.taxregistrationNumber,
        fatca_classification: companyDetails.fatca,
        description_of_business_activities: companyDetails.description,
        beneficialOwnerAgreed: benificialOwner,
        tax_residency: companyDetails.tax_residency,

        beneficialOwnerAgreedTs: companyDetails.benificialOwnerList,
        business_revenue: FormDataNetworth.buisnesRevenue,
        investment_funding: FormDataNetworth.InvestmentOrfundingProceeds,
        other_provide_details: FormDataNetworth.OtherProvideDetails,
        revenue_outside_ireland: FormDataNetworth.revenue ? true : false,
        countries_revenue: FormDataNetworth.countriesRevenue,
        no_of_directors: FormDataNetworth.numberOfDirectors,
      },
      investmentFromOthers: FormDataNetworth.OtherProvideDetailsPercent,

    };

    dispatch(updateEntity(updateBody));
    setIsEditingCompany(false);
  };
  const [checkboxSet, setCheckboxSet] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
  });
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [numberOfSignedPeople, setNumberOfSignedPeople] = useState(1);
  const [initialRequirements, setInitialRequirement] = useState({
    investmentAmount: '',
    EURReserve: '',
    USDReserve: '',

    smartReserveAccountConfirm: {
      companyAccount: true,
      over18: false,
      beneficialOwner: false,
      residentInIreland: false,
      taxResidentAndPPS: false,
      minimumInvestment: false,
      investmentObjective: false,
      // fieldConfirmationCheck: false,
    },
    termsAndPolicies: {
      termsOfBusiness: false,
      privacyPolicy: false,
      remunerationPolicy: false,
    },
  });
  const [sumValueError, setSumValueError] = useState('');
  const [LiquidNetWorthError, setLiquidNetWorthError] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState(1);
  const [selectedQuestion2, setSelectedQuestion2] = useState(1);
  const [selectedQuestion3, setSelectedQuestion3] = useState(1);
  const [formDataQuestions, setFormDataQuestions] = useState({
    securityquestionmotherandfathermeet: '',
    securityquestioncartooncharacter: '',
    securityquestionfirstpet: '',
  });
  const [FormDataQuestions2, setFormDataQuestions2] = useState({
    securityquestionsportsteam: '',
    securityquestiongrandmothername: '',
    securityquestionbestfriend: '',
  });
  const [FormDataQuestions3, setFormDataQuestions3] = useState({
    securityquestionnameofschool: '',
    securityquestionfatheroccupation: '',
    securityquestionfavouritebook: '',
  });
  const handleQuestionChange = question => {
    setSelectedQuestion(question);
    setFormDataQuestions({
      ...formDataQuestions,
      securityquestionmotherandfathermeet: '',
      securityquestioncartooncharacter: '',
      securityquestionfirstpet: '',
    });
  };
  const handleQuestionChange2 = question => {
    setSelectedQuestion2(question);
    setFormDataQuestions2({
      ...FormDataQuestions2,
      securityquestionsportsteam: '',
      securityquestiongrandmothername: '',
      securityquestionbestfriend: '',
    });
  };

  const handleQuestionChange3 = question => {
    setSelectedQuestion3(question);
    setFormDataQuestions3({
      ...FormDataQuestions3,
      securityquestionnameofschool: '',
      securityquestionfatheroccupation: '',
      securityquestionfavouritebook: '',
    });
  };

  const handleCompanySecurityQuestionChange = e => {
    const { name: checkboxCompanySecurityOneName, value } = e.target;
    setFormDataQuestions(prevState => ({
      ...prevState,
      [checkboxCompanySecurityOneName]: value,
    }));
  };

  const handleCompanySecurityQuestionChangeTwo = e => {
    const { name: checkboxCompanySecurityTwoName, value } = e.target;
    setFormDataQuestions2(prevState => ({
      ...prevState,
      [checkboxCompanySecurityTwoName]: value,
    }));
  };

  const handleCompanySecurityQuestionChangeThree = e => {
    const { name: checkboxCompanySecurityThreeName, value } = e.target;
    setFormDataQuestions3(prevState => ({
      ...prevState,
      [checkboxCompanySecurityThreeName]: value,
    }));
  };




  const [ibanError, setIbanError] = useState('');
  const IBAN_REGEX = /^[A-Z]{2}\d{2}[A-Z0-9]{4}\d{6}\d{8}$/;

  const handleChangeIban = e => {
    const { value } = e.target;

    setFormDataNetworth(prevState => ({
      ...prevState,
      iban: value,
    }));
    if (IBAN_REGEX.test(value)) {
      setIbanError('');
    } else {
      setIbanError('Invalid IBAN format. Expected format: 2 CapitalLetters, 2 digits, 4 CapitalCharacters, 14 digits.');
    }
    clearValidationError('iban');

  };


  const [validationMessage, setValidationMessage] = useState('');

  const [companyDetails, setCompanyDetails] = useState({
    companyname: '',
    tradingName: '',
    companyRegistrationNumber: '',
    country_of_tax_residency: '',
    description: '',
    Lei: '',
    tax_registration_number: false,
    selectedOptionLIE: '',
    benificialOwner: '',
    benificialOwnerList: '',
    tax_residency: false,
    taxregistrationNumber: '',
    fatca: '',
  });


  const [FormDataNetworth, setFormDataNetworth] = useState({
    totalNetWorth: '',
    liquidnetworth: '',
    totalnetincome: '',
    revenue: '',
    countriesRevenue: '',
    buisnesRevenue: '',
    InvestmentOrfundingProceeds: '',
    OtherProvideDetails: '',
    OtherProvideDetailsPercent: '',
    accountHolderName: '',
    bankName: '',
    iban: '',
    numberOfDirectors: '0',
  });


  // const areRequiredFieldsEmpty = () => {
  //   if (!key_person_details || !key_person_details.authorized_person_details) {
  //     return true;
  //   }
  //   return key_person_details.authorized_person_details.some(person =>
  //     !person.firstname || !person.lastname || !person.role
  //   );
  // };
  const areRequiredFieldsEmpty = () => {
    if (!key_person_details || !key_person_details?.authorized_person_details) {
      return true;
    }

    const personDetails = key_person_details?.authorized_person_details;

    // Check if any entry is missing required fields
    const areAnyRowsEmpty = personDetails.some(person =>
      !person?.firstname || !person?.lastname || !person?.role|| isEmpty(person?.role)
    );
    return areAnyRowsEmpty;
  };

  const [initialRequirementsQuestions, setInitialRequirementQuestions] = useState({
    securityquestionmotherandfathermeet: '',
    securityquestioncartooncharacter: '',
    securityquestionfirstpet: '',
  });

  const toggle = () => {
    setOpen((prev) => !prev);
    navigate('/');
  };

  const handletaxresident = (e) => {
    const value = e.target.value === "true";
    setCompanyDetails(prevState => ({
      ...prevState,
      tax_residency: value,
    }));
    clearValidationError('tax_residency');
    clearsecondValidationError('tax_residency')


  };


  const handleStateOrProvince = (e: any) => {
    const selectedValue = e.target.value;
    setCompanyAddressDetails({ ...companyAddressDetails, state_or_province: selectedValue });
    clearValidationError('state_or_province');

  };
  const handleCountryChange = (e: any) => {
    setCompanyAddressDetails({ ...companyAddressDetails, country: e.target.value });
    clearValidationError('country');
  };
  const handleTownOrCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyAddressDetails({ ...companyAddressDetails, townorcity: e.target.value });
    clearValidationError('townorcity')
  };
  const handlecompanyPostcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyAddressDetails({ ...companyAddressDetails, postcode: e.target.value });
    clearValidationError('postcode');
  };
  const handlecompanyStreetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyAddressDetails({ ...companyAddressDetails, addressLine1: e.target.value });
    clearValidationError('addressLine1');
  };
  const handleChangeFatca = e => {
    setCompanyDetails({ ...companyDetails, fatca: e.target.value });
  };

  const handleChangeTaxRegistrationNumber = e => {
    setCompanyDetails({ ...companyDetails, taxregistrationNumber: e.target.value });
    clearsecondValidationError('taxregistrationNumber')

  };

  // const handlecompanymobilenumber = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const inputValue = e.target.value;
  //   const regex = /^\+353\d{0,9}$/;

  //   if (inputValue.length <= 13 && regex.test(inputValue)) {
  //     setCompanyAddressDetails({ ...companyAddressDetails, accountHolderMobileNumber: inputValue });
  //     setValidationMessage('');
  //     clearsecondValidationError('accountHolderMobileNumber');
  //   } else if (inputValue.length > 13) {
  //     setValidationMessage('');
  //     clearsecondValidationError('accountHolderMobileNumber');
  //   } else {
  //     setValidationMessage('Please enter a valid mobile number starting with +353 and up to 9 digits.');
  //     clearsecondValidationError('accountHolderMobileNumber');
  //   }
  // };

  const handlecompanymobilenumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const regex = /^\+353\d{0,9}$/;

    if (inputValue.length <= 13 && regex.test(inputValue)) {
      setCompanyAddressDetails({ ...companyAddressDetails, accountHolderMobileNumber: inputValue });
      setValidationMessage('');
      if (inputValue.length === 13) {
        clearsecondValidationError('accountHolderMobileNumber');
      }
    }
    else if (inputValue.length > 13 || inputValue.length === 13) {
      setValidationMessage('');
      clearsecondValidationError('accountHolderMobileNumber');
    }
    else if (inputValue.length !== 13) {
      setValidationMessage('Please enter a valid mobile number starting with +353 and up to 9 digits.');

    }

    else {
      setValidationMessage('');
      clearsecondValidationError('accountHolderMobileNumber');
    }
  };



  const handleInputChangeWorth = e => {
    const totalNetWorth = e.target.value.replace(/[^\d.]/g, '');
    setRawValue(totalNetWorth);
    setFormDataNetworth(prevState => ({ ...prevState, totalNetWorth }));


    if (!totalNetWorth) {
      setValidationError('This field is mandatory');
    } else {
      setValidationError('');
    }
    clearValidationError('rawValue');

  };

  const formatCurrency = amount => {
    if (amount === '') {
      return '';
    }

    const parsedAmount = parseFloat(amount);
    return
    //  parsedAmount.toLocaleString('en-EN', {
    //   style: 'currency',
    //   currency: 'EUR',
    //   minimumFractionDigits: 0,
    // });
  };

  const handleLiquidNetWorthChange = e => {
    const { totalNetWorth: totalNetWorth } = FormDataNetworth;
    const liquidNetWorth = e.target.value.replace(/\D/g, '');

    const totalNetWorthNum = parseFloat(totalNetWorth);
    const liquidNetWorthNum = parseFloat(liquidNetWorth);

    if (liquidNetWorthNum > totalNetWorthNum) {
      setLiquidNetWorthError('Liquid net worth cannot be greater than total net worth.');
    } else {
      setLiquidNetWorthError('');
    }
    setFormDataNetworth({ ...FormDataNetworth, liquidnetworth: liquidNetWorth });
    clearValidationError('liquidnetworth');

  };
  const handleInputChangeIncome = e => {
    const totalnetincome = e.target.value.replace(/[^\d.]/g, '');
    setFormDataNetworth(prevState => ({ ...prevState, totalnetincome }));
    clearValidationError('totalnetincome');

  };

  const handleChangeRevenue = e => {
    setFormDataNetworth({ ...FormDataNetworth, revenue: e.target.value });
    clearValidationError('revenue');

  };

  const handleChangeCountriesRevenue = e => {
    setFormDataNetworth({ ...FormDataNetworth, countriesRevenue: e.target.value });
    clearValidationError('countriesRevenue');

  };

  const handleInputChanged = (fieldName, value) => {
    if (/^\d*$/.test(value) && value >= 0 && value <= 100) {
      setFormDataNetworth(prevState => ({ ...prevState, [fieldName]: value }));
    } else if (value === '') {
      setFormDataNetworth(prevState => ({ ...prevState, [fieldName]: '' }));
    }
    clearValidationError('buisnesRevenue');
    clearValidationError('OtherProvideDetailsPercent');


  };

  const isAllSelected = () => {
    return (

      (initialRequirements.EURReserve || initialRequirements.USDReserve) &&
      initialRequirements.smartReserveAccountConfirm.over18 &&
      initialRequirements.smartReserveAccountConfirm.beneficialOwner &&
      initialRequirements.smartReserveAccountConfirm.residentInIreland &&
      initialRequirements.smartReserveAccountConfirm.taxResidentAndPPS &&



      // initialRequirements.smartReserveAccountConfirm.minimumInvestment &&
      initialRequirements.smartReserveAccountConfirm.investmentObjective &&
      !investError &&
      initialRequirements.investmentAmount
      // initialRequirements.smartReserveAccountConfirm.fieldConfirmationCheck
    );
  };

  interface IValidationErrors {
    [key: string]: string;
  }
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({});
  const handleNextStep = async (_showDiv: number) => {
    if (_showDiv === 1) {
      const formBody: any = {
        accountId: companyData.accountId,
        is_company_account: true,
        // accountId: AccountIdNum,
        accountMasterType: 'Starlight Smart Reserve',
        accountHolderType: 'Organization',
        investmentAmount: initialRequirements.investmentAmount,
        productType: [initialRequirements.USDReserve ? 'USD Reserve' : null, initialRequirements.EURReserve ? 'EUR Reserve' : null].filter(
          value => value !== null,
        ),
        isAgeOverAgreed: initialRequirements.smartReserveAccountConfirm.over18,
        beneficialOwnerAgreed: initialRequirements.smartReserveAccountConfirm.beneficialOwner,
        residentInIrelandAgreed: initialRequirements.smartReserveAccountConfirm.residentInIreland,
        lumpSumAvailableToInvest: initialRequirements.smartReserveAccountConfirm.minimumInvestment,
        privacyPolicy: initialRequirements.termsAndPolicies.privacyPolicy,
        last_level: 1,
      };
      try {
        const response = await dispatch(createEntity(formBody));
        if (response?.payload?.data) {
          setAccountId(response.payload.data.accountId);
          sessionStorage.setItem('accountid', response?.payload?.data?.accountId);
          setShowdiv(_showDiv + 1);
          scrollToTop();
        }
      } catch (error) {
        alert('Error found');
      }
    }
  };
  //sig


  const handleSosSignatureUpdate = async (signaturetype, signatureValue) => {
    if (!accountsEntity) {
      return;
    }

    const payload = {
      accountId: accountsEntity,
      signaturetype,
      signed_by: signatureValue,
      signed_date: new Date().toISOString().split('T')[0],
    };

    try {
      dispatch(signature(payload)).then((response: any) => {
        const updatedSignature = response.payload;

        if (signaturetype === 'sos') {
          if (signatureValue === sosPrimary_sign) {
            setsosPrimarySign(updatedSignature?.primary_sign || sosPrimary_sign);
          }
        }
      });
    }
    catch (error) {
      console.error('Failed to update signature:', error);
    }
  };


  const handleSmartReserveSignatureUpdate = (signaturetype, signatureValue) => {
    if (!accountsEntity) {
      return;
    }

    const payload = {
      accountId: accountsEntity,
      signaturetype,
      signed_by: signatureValue,
      signed_date: new Date().toISOString().split('T')[0],
    };

    try {
      dispatch(signature(payload)).then((response: any) => {
        const updatedSignature = response.payload;

        if (signaturetype === 'smart_reserve') {
          if (signatureValue === smart_reserve_primary_sign) {
            setsmart_reserve_primary_sign(updatedSignature?.primary_sign || smart_reserve_primary_sign);
          }
        }

      });
    }
    catch (error) { /* empty */ }
  };
  const handleIBSignatureUpdate = (signaturetype, signatureValue) => {
    let accountId = accountsEntity?.toString() || '';
    if (!accountId && accountId === '') {
      if (signatureData?.hasOwnProperty('statement_of_suitability') && signatureData?.statement_of_suitability?.hasOwnProperty('account_id')) {
        accountId = signatureData?.statement_of_suitability?.account_id;
      } else {
        return;
      }
    }

    const payload = {
      accountId,
      signaturetype,
      signed_by: ibkrCompany_sign,
      signed_date: new Date().toISOString().split('T')[0],
    };

    try {
      dispatch(signature(payload)).then((response: any) => {
        const updatedSignature = response.payload;


        if (signaturetype === 'ibkr') {
          if (signatureValue === ibkrCompany_sign) {
            setibkrCompany_Sign(updatedSignature?.primary_sign || ibkrCompany_sign);
          }
        }
        if (updatedSignature) {
          handleUpdateNextStep(showdiv);
        }
      });
    } catch (error) { /* empty */ }
  };
  const handleUpdateNextStep = async (_showDiv: number) => {

    if (_showDiv === 2) {
      const _errors: IValidationErrors = {};

      if (!companyDetails.companyname) {
        _errors.companyname = 'This field is mandatory';
      }
      if (!companyDetails.companyRegistrationNumber) {
        _errors.companyRegistrationNumber = 'This field is mandatory';
      }
      if (!proof_of_company_doc) {
        _errors.proof_of_company_doc = 'This field is mandatory';
      }
      // if (!companyDetails.tax_residency) {
      //   _errors.tax_residency = 'This field is mandatory';
      //   clearsecondValidationError('tax_residency')

      // }

      if (companyDetails?.tax_residency === true && !companyDetails?.taxregistrationNumber) {
        _errors.taxregistrationNumber = 'This field is mandatory';
        clearsecondValidationError('taxregistrationNumber')

      }
      if (!companyAddressDetails.townorcity) {
        _errors.townorcity = 'This field is mandatory';
        clearsecondValidationError('townorcity')

      }
      if (!companyAddressDetails.postcode) {
        _errors.postcode = 'This field is mandatory';
        clearsecondValidationError('postcode')

      }
      if (!companyAddressDetails.addressLine1) {
        _errors.addressLine1 = 'This field is mandatory';
        clearsecondValidationError('addressLine1')

      }

      if (
        !companyAddressDetails.accountHolderMobileNumber ||
        companyAddressDetails.accountHolderMobileNumber === '+353' || companyAddressDetails.accountHolderMobileNumber.length !== 13
      ) {

        _errors.accountHolderMobileNumber = 'Please enter a valid mobile number starting with +353 and up to 9 digits.';

      }

      if (!companyDetails.description) {
        _errors.description = 'This field is mandatory';
      }

      // If there are errors, set the errors and do not proceed
      if (Object.keys(_errors).length > 0) {
        setValidationErrors(_errors);



        setToastMessage((prev) => 'Please fill all the mandatory fields');
        setToastType('error');
        setToastVisible(true);
        return;
      }
      setValidationErrors({});
      const updateBody = {
        accountId: companyData.accountId,
        is_company_account: true,
        company_details: {
          company_name: companyDetails.companyname,
          trading_name: companyDetails.tradingName,
          accountHolderName: customerDetails?.accountHolderName,
          registration_number: companyDetails.companyRegistrationNumber,

          lei: companyDetails.Lei,
          legal_entity_identifier: companyDetails.tax_registration_number,
          tax_registration_number: companyDetails.taxregistrationNumber,
          fatca_classification: companyDetails.fatca,
          tax_residency: companyDetails.tax_residency ? companyDetails.tax_residency : false,
          description_of_business_activities: companyDetails.description,
          beneficialOwnerAgreed: benificialOwner,
          beneficialOwnerAgreedTs: companyDetails.benificialOwnerList,
          proof_of_company_doc,
        },
        accountHolderMobileNumber: companyAddressDetails.accountHolderMobileNumber,
        state_or_province: companyAddressDetails?.state_or_province || '',
        townOrCity: companyAddressDetails.townorcity,
        country: companyAddressDetails.country,
        postcode: companyAddressDetails.postcode,
        addressLine1: companyAddressDetails.addressLine1,
        last_level: 2,
      };
      dispatch(updateEntity(updateBody));
      setShowdiv((prev: number) => prev + 1);
      scrollToTop();
    }
    if (_showDiv === 3) {
      const updateBody = {
        accountId: companyData.accountId,
        is_company_account: true,
        company_details: {
          company_name: companyDetails.companyname,
          trading_name: companyDetails.tradingName,
          registration_number: companyDetails.companyRegistrationNumber,

          lei: companyDetails.Lei,
          legal_entity_identifier: companyDetails.tax_registration_number,
          tax_registration_number: companyDetails.taxregistrationNumber,
          fatca_classification: companyDetails.fatca,

          tax_residency: mainAccountsEntity?.company_details?.tax_residency || false,
          description_of_business_activities: companyDetails?.description,
          beneficialOwnerAgreed: benificialOwner,
          beneficialOwnerAgreedTs: companyDetails?.benificialOwnerList,
          proof_of_company_doc,
          authorized_person_details: key_person_details?.authorized_person_details,
        },
        last_level: 3,
      }
      const response: any = await dispatch(updateEntity(updateBody));

      setAccountsIdNumber(response?.payload?.data?.accountId)
      setShowdiv(prev => prev + 1);
      scrollToTop();
    }
    if (_showDiv === 4) {

      const _errors: IValidationErrors = {};

      key_person_details.authorized_person_details.forEach((person, index) => {
        if (!person.passport_number) {
          _errors[`authorized_person_details_${index}_passport_number`] = 'This field is mandatory';
        }
        if (!person.company_email_address) {
          _errors[`authorized_person_details_${index}_company_email_address`] = 'This field is mandatory';
        }
        if (!person.mobile_number || person.mobile_number === '+353' || person.mobile_number.length !== 13) {
          _errors[`authorized_person_details_${index}_mobile_number`] = 'Please enter a valid mobile number starting with +353 and up to 9 digits.';
        }
        if (!person.date_of_bith) {
          _errors[`authorized_person_details_${index}date_of_bith`] = 'This field is mandatory';
        }
        if (!person.pps_number) {
          _errors[`authorized_person_details_${index}pps_number`] = 'This field is mandatory';
        }
        if (!person.home_address) {
          _errors[`authorized_person_details_${index}home_address`] = 'This field is mandatory';
        }

        if (!person.passport_doc) {
          _errors[`authorized_person_details_${index}passport_doc`] = 'Passport doc is mandatory';
        }
        if (!person.proof_of_address) {
          _errors[`authorized_person_details_${index}proof_of_address`] = 'Proof of address is mandatory';
        }

        if (!person?.address?.city) {
          _errors[`authorized_person_details_${index}_address_city`] = 'City is mandatory';
        }
        if (!person?.address?.postal_code) {
          _errors[`authorized_person_details_${index}_address_postal_code`] = 'Post Code is mandatory';
        }
        if (!person?.address?.street) {
          _errors[`authorized_person_details_${index}_address_street`] = 'Street is mandatory';
        }
      });

      if (Object.keys(_errors).length > 0) {
        setValidationErrors(_errors);
        setToastMessage((prev) => 'Please fill all the mandatory fields');
        setToastType('error');
        setToastVisible(true);
        return;
      }
      setValidationErrors({});

      dispatch(updateEntity({ ...mainAccountsEntity, last_level: 4 }));

      setShowdiv((prev: number) => prev + 1);
      scrollToTop();
    }
    if (_showDiv === 5) {
      const _errors: IValidationErrors = {};

      if (!rawValue) {
        _errors.rawValue = 'This field is mandatory';
        // clearValidationError('rawValue');

      }
      if (!FormDataNetworth?.liquidnetworth) {
        _errors.liquidnetworth = 'This field is mandatory';

      }
      if (!FormDataNetworth?.totalnetincome) {
        _errors.totalnetincome = 'This field is mandatory';

      }
      if (!FormDataNetworth.revenue) {
        _errors.revenue = 'This field is mandatory';

      }

      if (FormDataNetworth.revenue === "Yes") {
        if (!FormDataNetworth.countriesRevenue) {
          _errors.countriesRevenue = 'This field is mandatory';
        }
      }

      // if (!FormDataNetworth.buisnesRevenue) {
      //   _errors.buisnesRevenue = 'This field is mandatory';

      // }
      // if (!FormDataNetworth.InvestmentOrfundingProceeds) {
      //   _errors.InvestmentOrfundingProceeds = 'This field is mandatory';

      // }
      // if (!FormDataNetworth.OtherProvideDetailsPercent) {
      //   _errors.OtherProvideDetailsPercent = 'This field is mandatory';

      // }


      // if (!FormDataNetworth.OtherProvideDetails) {
      //   _errors.OtherProvideDetails = 'This field is mandatory';
      // }

      if (Object.keys(_errors).length > 0) {
        setValidationErrors(_errors);

        setToastMessage((prev) => 'Please fill all the mandatory fields');
        setToastType('error');
        setToastVisible(true);
        return;
      }
      const updateBody = {
        accountId: companyData.accountId,
        is_company_account: true,
        totalNetWorth: Number(FormDataNetworth.totalNetWorth),
        liquidNetWorth: Number(FormDataNetworth.liquidnetworth),
        totalNetIncome: Number(FormDataNetworth.totalnetincome),

        accountHolderName: FormDataNetworth.accountHolderName,
        nameOfBank: FormDataNetworth.bankName,
        iban: FormDataNetworth.iban,
        company_details: {
          business_revenue: FormDataNetworth.buisnesRevenue,
          investment_funding: FormDataNetworth.InvestmentOrfundingProceeds,
          other_provide_details: FormDataNetworth.OtherProvideDetails,

          revenue_outside_ireland: FormDataNetworth.revenue ? true : false,
          countries_revenue: FormDataNetworth.countriesRevenue,
          // no_of_directors: FormDataNetworth.numberOfDirectors,
        },
        investmentFromOthers: FormDataNetworth.OtherProvideDetailsPercent,
        last_level: 5,
      };
      dispatch(updateEntity(updateBody));

      setShowdiv((prev: number) => prev + 1);
      scrollToTop();
    }
    if (_showDiv === 6) {
      const updateBody = {
        accountId: companyData.accountId,
        last_level: 99,
      }
      dispatch(updateEntity(updateBody));
      setShowdiv((prev: number) => prev + 1);
      setOpen7(true)
      // if (open7) {
      //   setOpen2(true);
      // }
      // setOpen2(true);
      scrollToTop();
    }
    if (_showDiv === 7) {
      const updateBody = {
        accountId: companyData.accountId,
        authorisePosition:authorisePosition,
        last_level: 100
      }
      dispatch(updateEntity(updateBody));
      setShowdiv((prev: number) => prev + 1);
      setOpen3(true)
      // handleSosSignatureUpdate('sos');
      scrollToTop();
    }
    if (_showDiv === 8) {
      const updateBody = {
        accountId: companyData.accountId,
        smartReserveAuthorisePosition:smartReserveAuthorisePosition,
        last_level: 101
      }
      dispatch(updateEntity(updateBody));
      setOpen(true);

      // setShowdiv((prev: number) => prev + 1);

      // handleSosSignatureUpdate('sos');
      scrollToTop();
    }
   
  };

  const handlePreviousStep = (_showDiv: number) => {
    if (_showDiv === 1) {
      if (currentFormIndex > 0) {
        setCurrentFormIndex(currentFormIndex - 1);
        scrollToTop();
      }
    } else {
      setShowdiv(_showDiv - 1);
      scrollToTop();
    }
  };
  const handleInputChange = e => {
    const { name: checkboxName, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    if (checkboxName.includes('.')) {
      const [parentKey, childKey] = checkboxName.split('.');
      setInitialRequirement({
        ...initialRequirements,
        [parentKey]: {
          ...initialRequirements[parentKey],
          [childKey]: newValue,
        },
      });
    } else {
      setInitialRequirement({
        ...initialRequirements,
        [checkboxName]: newValue,
      });
    }
  };
  const handleChangeCompanyDetails = e => {
    const { name: checkboxCompanyName, value, type, checked } = e.target;

    setCompanyDetails(prevState => ({
      ...prevState,
      [checkboxCompanyName]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleInvestment = value => {
    if (/^\d*$/.test(value) && value >= 0 && value <= 100) {
      setFormDataNetworth(prevState => ({ ...prevState, InvestmentOrfundingProceeds: value }));
    } else if (value === '') {
      setFormDataNetworth(prevState => ({ ...prevState, InvestmentOrfundingProceeds: '' }));
    }
    clearValidationError('InvestmentOrfundingProceeds');

  };



  const clearValidationError = (field: string) => {
    setValidationErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleInputOtherChanges = (fieldName, value) => {
    setFormDataNetworth(prevState => ({ ...prevState, [fieldName]: value }));
    clearValidationError('OtherProvideDetails');
  };

  const handleChangeAccountHolderName = e => {
    setFormDataNetworth({ ...FormDataNetworth, accountHolderName: e.target.value });
    clearValidationError('accountHolderName');


  };

  const handleChangeBankName = e => {
    setFormDataNetworth({ ...FormDataNetworth, bankName: e.target.value });
    clearValidationError('bankName');

  };

  // const handleChangeIban = e => {
  //   setFormDataNetworth({ ...FormDataNetworth, iban: e.target.value });
  //   clearValidationError('iban');

  // };


  const handleChangeCompanyName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyDetails({ ...companyDetails, companyname: e.target.value });
    clearValidationError('companyname');
  };

  const handleChangetraiding = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyDetails({ ...companyDetails, tradingName: e.target.value });
    clearValidationError('tradingName')
  };

  const handleChangecompanyRegistrationNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyDetails({ ...companyDetails, companyRegistrationNumber: e.target.value });
    clearValidationError('companyRegistrationNumber')
  };

  const handleChangecompanydescription = e => {
    setCompanyDetails({ ...companyDetails, description: e.target.value });
    clearValidationError('description')

  };

  const handleLegalEntityIdentifier = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyDetails({ ...companyDetails, Lei: e.target.value });
    if (!companyDetails.Lei) {
      setValidationError('This field is mandatory');
    } else {
      setValidationError('');
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputVal = e.target.value;
    const numericValue = parseFloat(inputVal.replace(/[^\d.]/g, ''));

    // Update state correctly using functional update
    setInitialRequirement(prevState => ({
      ...prevState,
      investmentAmount: numericValue.toString(), // Update investmentAmount with numericValue or empty string
    }));

    // Perform validation based on numericValue
    if (!isNaN(numericValue) && numericValue >= 50000) {
      setInvestError('');
    } else {
      setInvestError(numericValue ? 'Minimum value should be €50,000' : 'This field is mandatory');
    }
  };


  const handleAuthorizedPersonSubmit = async (): Promise<void> => {
    if (AuthorizedPersonRef.current) {
      const response = await AuthorizedPersonRef.current.syncAuthorizedPersonRefServer();
      if (response) {
        setShowdiv((prev: number) => prev + 1);
        scrollToTop();
      }
    }
  };

  useEffect(() => {
    const accountId = accountsEntity?.toString() || '';

    if (accountId) {
      dispatch(getEntity(accountId))
        .then((new_response: any) => {

          const data = new_response?.payload?.data;
          if (data) {

            setCompanyDetails((prev) => ({
              ...prev,
              ...data?.company_details,
              companyname: data?.company_details?.company_name,
              tradingName: data?.company_details?.trading_name,
              companyRegistrationNumber: data?.company_details?.registration_number,
              description: data?.company_details?.description_of_business_activities,
              Lei: data?.company_details?.lei,
              tax_registration_number: data?.company_details?.tax_residency,
              fatca: data?.company_details?.fatca_classification,
              taxregistrationNumber: data?.company_details?.tax_registration_number
            }));
            setFormDataNetworth((prev) => ({
              ...prev,
              ...data,
            }));
            setkey_person_details((prev) => ({
              ...prev,
              ...data?.company_details,
            }));
            setproof_of_company_doc(data?.company_details?.proof_of_company_doc);
            setCompanyAddressDetails((prev) => ({
              ...prev,
              ...data,
              townorcity: data?.townOrCity
            }));
            setFormDataNetworth((prev) => ({
              ...prev,
              ...data,
              totalNetWorth: data.totalNetWorth,
              liquidnetworth: data.liquidNetWorth,
              totalnetincome: data.totalNetIncome,
              revenue: data?.company_details?.revenue_outside_ireland,
              countriesRevenue: data?.company_details?.countries_revenue,
              buisnesRevenue: data?.company_details?.business_revenue,
              InvestmentOrfundingProceeds: data?.company_details?.investment_funding,
              OtherProvideDetailsPercent: data?.investmentFromOthers,
              OtherProvideDetails: data?.company_details?.other_provide_details
            }));
          }
        })
        .catch((error) => {
          console.error('Failed to fetch account entity:', error);
        });
    }
  }, [accountsEntity,
    dispatch,
    setCompanyDetails,
    setFormDataNetworth,
    setkey_person_details,
    setproof_of_company_doc,
    setCompanyAddressDetails,
    setFormDataNetworth,

  ]);


  //================================================================//
  // Latest Changes / Bug Fixes from InitialRequirement component layer
  //================================================================//

  const CompanyInitialRequirementRef = React.useRef<{ syncCompanyInitialRequirementWithServer: () => Promise<any> }>(null);
  const [companyInitialRequirementButton, setCompanyInitialRequirementButton] = useState<boolean>(false);

  const handleCompanyInitialRequirementSubmit = async (): Promise<void> => {
    if (CompanyInitialRequirementRef.current) {
      const response = await CompanyInitialRequirementRef.current.syncCompanyInitialRequirementWithServer();

      if (response) {
        setShowdiv((prev: number) => prev + 1);
        scrollToTop();
      }
    }
  };

  //================================================================//
  // Latest Changes / Bug Fixes from CompanyPeaople component layer
  //================================================================//

  const CompanyPeopleRef = React.useRef<{ syncCompanyPeopleWithServer: () => Promise<any> }>(null);
  const [CompanyPeopleButton, setCompanyPeopleButton] = useState<boolean>(false);

  const handleCompanyPeopleSubmit = async (): Promise<void> => {
    if (CompanyPeopleRef.current) {
      const response = await CompanyPeopleRef.current.syncCompanyPeopleWithServer();

      if (response) {
        setShowdiv((prev: number) => prev + 1);
        scrollToTop();
      }
    }
  };


  const totalSteps = 8;

  const calculateProgress = (currentStep: number): number => {
    if (currentStep <= 1) {
      return 0;  
    }
    if (currentStep >= totalSteps) {
      return 100; 
    }
    return ((currentStep - 1) / (totalSteps - 1)) * 100;
  };
  
  useEffect(() => {
    setShowdivprogress(calculateProgress(showdiv)); 
    setShowdivper(Math.round(calculateProgress(showdiv))); 
  }, [showdiv]);
  


  return (
    <div>
      <CommonToast
        visible={toastVisible}
        onClose={() => setToastVisible(false)}
        title={toastType === 'success' ? 'Success' : 'Error'}
        description={toastMessage}
        type={toastType}
        isAutoClose={true}


      />
      <Row>
        <Col md="12" className="custom-bg-account ">
          <div className="d-md-flex justify-content-md-center">
            <Form className="form-account-custom ">
            <Row className="text-center">
                <span className="text-start">{showdivper} % completed</span>
                <Col md="12" className="pt-3">
                  <Progress className="Progress-account-custom" value={showdivprogress} max="100" />
                </Col>
              </Row>
              {showdiv === 1 && (
                <div>
                  <Row className="form-firstrow-account-custom pt-3">
                    <Col md="12" className="form-firstrow-account-head-custom">
                      Initial Requirements Check
                    </Col>
                  </Row>
                  <Row className="pb-3">
                    <CompanyInitialRequirement ref={CompanyInitialRequirementRef} onFormComplete={(status) => { setCompanyInitialRequirementButton(status) }} />
                  </Row>
                  <Row>
                    <FormGroup>
                      <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <Col md="12 d-flex justify-content-md-end pt-3">
                      <div className="p-1">
                        <Button
                          className="button-account-custom-submit"
                          onClick={() => { handleCompanyInitialRequirementSubmit(); }}
                          disabled={!companyInitialRequirementButton}
                        >
                          Continue
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              {showdiv === 2 && (
                <div>
                  <Row>
                    <Col md="12 pt-3" className="form-firstrow-account-head-custom"></Col>
                    <Col md="12" className="pt-3">
                      We need some basic details to start your Company application process
                    </Col>
                    <Row>
                      <CompanyDetails
                        ref={companyDetailsRef} onFormComplete={(status) => { setCompanyDetailsButton(status) }}

                      // companyDetails={companyDetails}
                      // handleStateOrProvince={handleStateOrProvince}
                      // handleCountryChange={handleCountryChange}
                      // stateList={stateList}
                      // handleTownOrCityChange={handleTownOrCityChange}
                      // handlecompanyPostcodeChange={handlecompanyPostcodeChange}
                      // benificialOwner={benificialOwner}
                      // setbenificialOwner={setbenificialOwner}
                      // selectedOptionLIE={selectedOptionLIE}
                      // setSelectedOptionLIE={setSelectedOptionLIE}
                      // handletaxresident={handletaxresident}
                      // handleChangeCompanyDetails={handleChangeCompanyDetails}
                      // handleChangeCompanyName={handleChangeCompanyName}
                      // companyname={companyDetails.companyname}
                      // handleChangetraiding={handleChangetraiding}
                      // handleChangecompanyRegistrationNumber={handleChangecompanyRegistrationNumber}
                      // handleChangecompanydescription={handleChangecompanydescription}
                      // handleLegalEntityIdentifier={handleLegalEntityIdentifier}
                      // validationErrror={validationErrror}
                      // setValidationError={setValidationError}
                      // handleChangeTaxRegistrationNumber={handleChangeTaxRegistrationNumber}
                      // handleChangeFatca={handleChangeFatca}
                      // setproof_of_company_doc={setproof_of_company_doc}
                      // proof_of_company_doc={proof_of_company_doc}
                      // validationErrors={validationErrors}
                      // validationMessage={validationMessage}
                      // handlecompanymobilenumber={handlecompanymobilenumber}
                      // companyAddressDetails={companyAddressDetails}
                      // handlecompanyStreetChange={handlecompanyStreetChange}
                      // countryList={countryList}
                      />
                    </Row>
                  </Row>
                  <Row>
                    <FormGroup>
                      <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <Col md="12 d-flex justify-content-md-end pt-3">
                      <div  className="p-1">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showdiv)}>
                          Previous
                        </Button>
                      </div>
                      <div className="p-1">
                        <Button className="button-account-custom-submit" onClick={() => { handleCompanyDetailsButtonSubmit(); }}
                        // disabled={companyDetails?.tax_residency === true && !companyDetails?.taxregistrationNumber}
                        // disabled={!(companyAddressDetails?.accountHolderMobileNumber)}
                        >
                          Continue
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              {showdiv === 3 && (
                <div>
                  <CompanyPeople
                    setkey_person_details={setkey_person_details}
                    key_person_details={key_person_details}
                    checkboxSet={checkboxSet}
                    setCheckboxSet={setCheckboxSet}
                    numberOfForms={numberOfForms}
                    setNumberOfForms={setNumberOfForms}
                    numberOfSignedPeople={numberOfSignedPeople}
                    setNumberOfSignedPeople={setNumberOfSignedPeople}
                    setfirstName={setNameValues}
                  />
                  <Row>
                    <FormGroup>
                      <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                    </FormGroup>
                  </Row>

                  <Row>
                    <Col md="12 d-flex justify-content-md-end pt-3">
                      <div className="p-1">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showdiv)}>
                          Previous
                        </Button>
                      </div>
                      <div className="p-1">
                        <Button className="button-account-custom-submit"
                          onClick={() => { handleUpdateNextStep(showdiv) }}
                          disabled={areRequiredFieldsEmpty()}
                        >
                          Continue

                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              {showdiv === 4 && (
                <>
                  <div className="py-5">
                    <AuthorizedPeopleForm ref={AuthorizedPersonRef} onFormComplete={(status) => { setAuthorizedPersonButton(status) }}
                    />
                    {/* <Row>
                      <FormGroup>
                        <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                      </FormGroup>
                    </Row> */}
                    <Row>
                      <Col md="12 d-flex justify-content-md-end pt-3">
                        <div className="p-1">
                          <Button
                            className="button-account-custom"
                            onClick={() => handlePreviousStep(showdiv)}
                          // disabled={currentSlide !== numberOfForms - numberOfForms}
                          >
                            Previous
                          </Button>
                        </div>

                        <div className="p-1">
                          <Button
                            className="button-account-custom-submit"
                            onClick={() => { handleAuthorizedPersonSubmit(); }}
                          >
                            Continue
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              )}

              {showdiv === 5 && (
                <div>
                  <Row>
                    <Col md="12 pt-3" className="form-firstrow-account-head-custom">
                      Company Financials
                    </Col>
                    <Row className="pt-3">
                      <CompanyFinancials
                        ref={companyFinancialRef}
                        onFormComplete={(status) => { setCompanyFinancialButton(status) }}
                      />
                    </Row>
                  </Row>
                  <Row>
                    <FormGroup>
                      <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <Col md="12 d-flex justify-content-md-end pt-3">
                      <div className="p-1">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showdiv)}>
                          Previous
                        </Button>
                      </div>
                      <div className="p-1">
                        <Button
                          className="button-account-custom-submit"
                          onClick={() => { handleCompanyFinancialSubmit(); 
                          }}
                          disabled={!(companyFinancialButton)}
                        >
                          Continue
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              {showdiv === 6 && (
                <div>
                  <Summary ref={SummaryRef} onFormComplete={(status) => { setSummaryButton(status) }}
                  />
                  <Row>
                    <FormGroup>
                      <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <Col md="12 d-flex justify-content-md-end pt-3">
                      <div className="p-1">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showdiv)}>
                          Previous
                        </Button>
                      </div>
                      <div className="p-1">
                        <Button className="button-account-custom-submit" onClick={() => { handleUpdateNextStep(showdiv) }}>
                          Continue
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              {showdiv === 7 && (
                <div>
                  <CompanySos
                    sosPrimary_sign={sosPrimary_sign}
                    setsosPrimarySign={setsosPrimarySign}
                    // name={name}
                    // lastName={lastName}
                    initialRequirements={initialRequirements}
                    companyDetails={companyDetails}
                    onBehalfOf={onBehalfOf}
                    setOnBehalfOf={setOnBehalfOf}
                    authorisePosition={authorisePosition}
                    setAuthorisePosition={setAuthorisePosition}

                    handleOnBehalfOf={handleOnBehalfOf}
                    handleAuthorisePosition={handleAuthorisePosition}
                  />
                  <Row>
                    <FormGroup>
                      <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <Col md="12 d-flex justify-content-md-end pt-3">
                      <div className="p-1">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showdiv)}>
                          Previous
                        </Button>
                      </div>
                      <div className="p-1">
                        <Button
                          className="button-account-custom-submit"
                          id="continueButton"
                          onClick={() => {
                            handleSosSignatureUpdate('sos', sosPrimary_sign);
                            handleUpdateNextStep(showdiv);
                          }}
                          disabled={!(sosPrimary_sign)}

                        >
                          Continue
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              {showdiv === 8 && (
                <div>
                  <MpsdDoc
                    accountHolderType={mainAccountsEntity?.accountHolderType}
                    smart_reserve_primary_sign={smart_reserve_primary_sign}
                    setsmart_reserve_primary_sign={setsmart_reserve_primary_sign}
                    companyDetails={companyDetails}
                    onBehalfOf={onBehalfOf}
                    smartReserveAuthorisePosition={smartReserveAuthorisePosition}
                    setSmart_reserve_authorise_position={setSmart_reserve_authorise_position}
                    handleAuthoriseMPSDPosition={handleAuthoriseMPSDPosition}
                  />
                  <Row>
                    <FormGroup>
                      <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <Col md="12 d-flex justify-content-md-end pt-3">
                      <div className="p-1">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showdiv)}>
                          Previous
                        </Button>
                      </div>
                      <div className="p-1">
                        <Button
                          className="button-account-custom-submit"
                          id="continueButton"
                          onClick={() => {
                            handleSmartReserveSignatureUpdate('smart_reserve', smart_reserve_primary_sign);
                            handleUpdateNextStep(showdiv);
                          }}


                          disabled={
                            mainAccountsEntity.customerId === customerId
                              ? !smart_reserve_primary_sign || !smartReserveAuthorisePosition
                              : !smartReserveAuthorisePosition
                          }
                        >
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              {showdiv === 9 && (
                <div>
                  <CompanyIbkr
                    companyDetails={companyDetails}
                    interactive={interactive}
                    accountIdNum={mainAccountsEntity?.accountId}
                    accountHolderType={mainAccountsEntity?.accountHolderType}

                    ibkrCompany_sign={ibkrCompany_sign}
                    setibkrCompany_Sign={setibkrCompany_Sign}
                    handleCheckboxibustomerAgreement={handleCheckboxibustomerAgreement}
                    handleCheckboxibclientFinancialAdvisor={handleCheckboxibclientFinancialAdvisor}
                    handleCheckboxelt={handleCheckboxelt}
                    handleCheckboxibsdNotification={handleCheckboxibsdNotification}
                    handleCheckboxLLA={handleCheckboxLLA}
                    authorisedPosition3={authorisedPosition3}
                    setAuthorisedPosition3={setAuthorisedPosition3}
                    handleAuthoriseIbkrPosition={handleAuthoriseIbkrPosition}
                  />
                  <Row>
                    <FormGroup>
                      <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <Col md="12 d-flex justify-content-md-end pt-3">
                      <div className="p-1">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showdiv)}>
                          Previous
                        </Button>
                      </div>
                      <div className="p-1">
                        <Button
                          className="button-account-custom-submit"
                          id="continueButton"
                          // onClick={() => {
                          //   handleIBSignatureUpdate('smart_reserve', smart_reserve_primary_sign);
                          //   handleUpdateNextStep(showdiv);
                          // }}
                          // disabled={!(smart_reserve_primary_sign)}
                          onClick={() => {
                            handleIBSignatureUpdate('ibkr', setibkrCompany_Sign);
                          }}

                          disabled={
                            // Check if any required interactive element is missing
                            !(interactive.elt &&
                              interactive.ib_client_financial_advisor &&
                              interactive.ib_customer_agreement &&
                              interactive.ibsd_notification &&
                              interactive.lla) ||
                            // If customerId exists, check if ibkrCompany_sign is false
                            (mainAccountsEntity.customerId === customerId ? !ibkrCompany_sign || !authorisedPosition3 : !authorisedPosition3)
                          }

                        >
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              <CommonToast
                visible={open7}

                title={'Thank You For Completing The Application Form'}
                needCloseButton={true}
                type="custom"
                isAutoClose={false}
                useWhiteOverlay={true}  

              >
                <div className="font-weight-bold custom-modal-style-big text-start" >
                  <br />
                  There are a few documents which we need you to sign before we can open your account. <br />
                  <br />
                  {/* A short description of each document is provided below, please review each document in the following screens and sign where indicated by typing your name. <br /> <br />
          <div className=''>1. Your Statement of Suitability</div>
          <div>  A Statement of Suitability is required by Irish regulation and sets out the reasons why the products or services offered or recommended by us are considered suitable for your particular needs, objectives, and circumstances.</div> <br /> <br />
          <div className=''>2. Starlight Smart Reserve Model Portfolio Description</div>
          <div>This document provides you with more information on how your funds will be invested.</div> <br /><br />
          <div className=''>3. W-8BEN Form</div> */}
                  <div>
                    {/* A W-8BEN form is US tax form. It enables Irish residents to receive preferential tax treatment in certain circumstances. <br />
            Whether you intend to invest in US investments or not, you are required to complete and sign this from. For more information on W-8BEN forms. */}
                    Please review each document on the following screens and sign where indicated by typing your <span className='' style={{ color: 'black', fontWeight: 'bold' }}>full name</span> in the box provided.

                    {/* <a href="https://www.starlightwealth.ie/faqs" className="" style={{ color: '#597177' }} rel="noreferrer">           
              Here
            </a> */}

                  </div><br /><br />
                  {/* <div className=''>4. Interactive Brokers Ireland Standard Terms And Disclosures</div>
          <div>Starlight Smart Reserve and Starlight Smart Invest are provided via the Starlight Smart Account, a self-directed advisory investment account opened in your name with our partner investment broker <span className='' style={{ color: 'red', fontWeight: 'bold' }}>Interactive Brokers</span> Ireland Limited. <br /><br />
            {`Interactive Brokers Ireland Limited is a MIFID authorised investment firm regulated by the Central Bank of Ireland. To open a Starlight Account, you must agree to Interactive Broker's standard terms and disclosures.`}</div>
        </div> */}
                  <br />
                </div>
                <Button color="primary" onClick={handleContinue}>Continue</Button>
              </CommonToast>

              <CommonToast
                visible={open2}
                title={'Your Statement of Suitability'}
                needCloseButton={true}
                type="custom"
                isAutoClose={false}
                useWhiteOverlay={true}  

              >
                <div className="font-weight-bold  custom-modal-style text-start">
                  <div className='' style={{ fontWeight: 600, textAlign: 'left', marginBottom: '10px' }}> </div>
                  A Statement of Suitability is required by Irish regulation and sets out the reasons why the products or services offered or recommended by us are considered suitable for your particular needs, objectives, and circumstances. Please review the document and sign to confirm your agreement.
                </div>
                <br />
                <Button color="primary" onClick={toggle2}>
                  Continue
                </Button>
              </CommonToast>

              <CommonToast
                visible={open3}
                // onClose={toggle7}
                title={'Starlight Smart Reserve Model Portfolio Description'}
                needCloseButton={true}
                type="custom"
                isAutoClose={false}
              >
                <div className="font-weight-bold custom-modal-style text-start" >

                This document provides you with more information on how your funds will be invested.
                This model portfolio of short-term Money Market Funds is designed for clients seeking to generate an institutional rate of return on their cash, that exceeds prevailing bank deposit rates, but with a similar risk profile.
                Please review the document, scroll down to the bottom and sign to confirm your agreement.
                  </div>
                <br />
                <Button color="primary" onClick={toggle3}>
                  Continue
                </Button>
              </CommonToast>
              <Row className='d-flex justify-content-center align-items-center'>
                <Col className='d-flex justify-content-center'>
                  <CommonToast
                    visible={open}
                    onClose={() => { toggle() }}
                    // title={'Test'}
                    needCloseButton={true}
                    type="custom"
                    isAutoClose={false}
                  >
                    <Row className='justify-content-center'>
                      <Col className='col-12'>
                        <div className="font-weight-bold custom-modal-style">
                          <span style={{ fontWeight: 600 }}>Thank you for applying for a<br />Starlight Smart Reserve Account</span> <br /><br />
                          <div>
                        Your application has been submitted. We will update you about your account status shortly. 
                        You can check your account status in <Link to="/user-profile" className="" style={{ color: '#597177' }}>
                          Client Portal
                        </Link>.
                        <br /><br />
                        </div>
                        We look forward to helping you create a brighter future for you and your family
                        </div>
                        <div className='d-flex justify-content-center mt-3 text-white'>
                          <Button color="primary" onClick={toggle} className='text-white'>
                            <a rel="noreferrer" href="https://www.starlightwealth.ie" style={{ color: '#eee' }}>Close</a>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CommonToast>
                </Col>
              </Row>
            </Form>
          </div>
        </Col >
      </Row >
    </div >
  )
};


export default CompanyAccounts;
