import React from 'react'

const MyStarLight = () => {
  return (
    <div className="col-12 d-flex justify-content-center text-start pt-5">
        <div className="row width-full-page pb-2 relative-div-bg">
          <div className="col-12">
              <div className="head-parent-div pt-3">
                  <div className="  head-text-dashboard">My Balance</div>
                      <div className="  head-text-dashboard-value">:</div>
                  </div>
                  <div className="head-parent-div pt-2">
                  <div className="  head-sub-text-dashboard">Earned interest</div>
                      <div className="  head-sub-text-dashboard-value">:</div>
                      </div>
                  <div className="head-parent-div pt-5">
                  <div className="  head-text-dashboard">My Starlight Portfolio</div>
                      <div className="  head-text-dashboard-value">:</div>
                      </div>
                  <div className="head-parent-div pt-2">
                  <div className="  head-sub-text-dashboard">Starlight Smart Reserve</div>
                      <div className="  head-sub-text-dashboard-value">:</div>
                      </div>
                  <div className="head-parent-div">
                  <div className="  head-sub-text-dashboard">Cash</div>
                  <div className="  head-sub-text-dashboard-value">:</div>
              </div>
          </div>
        </div>
    </div>
  )
}

export default MyStarLight